/**
 * Host Main Page
 * 
 * Show all of the hosts' active games, and allow them to select one
 */
import React from 'react'
import { navigate } from 'gatsby'
import dayjs from 'dayjs'

import HostContext from '../../utils/host-context'
import HostLoginWrapper from '../../components/host/login-wrapper'

import SvgCirclePlus from '../../components/svg/plus-circle'

export default class HostIndexPage extends React.Component {
  render() {
    return (
      <HostLoginWrapper>
        <HostContext.Consumer>
          {({ get_active_hunts, loaded }) => {
            const active_hunts = get_active_hunts()
            return (
              <>
                <div className="site-sub-header px-4 py-4 mb-4">
                  <h1 className={`title h5 text-uppercase mb-0`}>Choose a Hunt</h1>
                </div>
                <div className="content px-4 pb-5">
                  {!loaded && <p>Loading...</p>}
                  {loaded && !active_hunts.length && <p>You don't have any hunts today!</p>}
                  <div className="row">
                    {active_hunts.map(hunt => (
                      <div className="col-md-6" key={hunt.id}>
                        <div className="card mb-3" onClick={() => navigate(`/host/hunt/#${hunt.id}`)}>
                          <div className="card-header">
                            <div className="row">
                              <div className="col-9">
                                <h3 className="card-title mb-0">
                                  {hunt.display_title ? hunt.display_title : hunt.title}
                                </h3>
                              </div>
                              <div className={`col text-right`}>
                                <SvgCirclePlus />
                              </div>
                            </div>
                          </div>
                          <div className="card-body">
                            <p className="mb-0">
                              <span className={`d-block team-details`}>
                                <strong>Event Date</strong>
                                {` `}
                                {hunt.event_date
                                  ? dayjs(hunt.event_date).format(`MM/DD/YYYY h:mm A`)
                                  : dayjs(hunt.start).format(`MM/DD/YYYY h:mm A`)}
                              </span>
                              <span className={`d-block team-details`}>
                                <strong>Ends</strong>
                                {` `}
                                {dayjs(hunt.end).format(`MM/DD/YYYY h:mm A`)}
                              </span>
                              <span className={`d-block team-details`}>
                                <strong>Location</strong> {hunt.location}
                              </span>
                              <span className={`d-block team-details`}>
                                <strong>Client</strong> {hunt.client}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </>
            )
          }}
        </HostContext.Consumer>
      </HostLoginWrapper>
    )
  }
}
